import { Modal } from "antd";
import { useState } from "react";
import { useRouter } from "next/navigation";
import RequestCare from "./RequestCare";
import { useBooking } from "../contexts/BookingProvider";
import Image from "next/image";
import Link from "next/link";
const ProviderSummaryCard = ({
  // rate,
  headline,
  fullName,
  id,
  email,
  // book now will showModal and put this in Ls or <BookingContext/>
  photo,
  bioTextColor,
  bioButtonColor
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const router = useRouter();
  const {
    setFlow,
    setProviderEmail
  } = useBooking();
  const showModal = () => {
    // this function now routes to the request care page instead of setIsModalOpen(true).
    localStorage.setItem("providerEmail", email); // not sure this is useful
    localStorage.setItem("intent", "direct"); // not sure this is useful
    setFlow("directbooking");
    setProviderEmail(email);
    router.push("/request");
    // setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleFullBio = () => {
    router.push(`/fullbio?id=${encodeURIComponent(id)}`);
    localStorage.setItem("intent", "direct");
    setFlow("directbooking");
    setProviderEmail(email);
  };
  return <div className="flex flex-col gap-4 items-center justify-start max-w-full min-w-full sm:min-w-72 sm:max-w-72" data-sentry-component="ProviderSummaryCard" data-sentry-source-file="ProviderSummaryCard.jsx">
      <div className="w-full" onClick={handleFullBio}>
        <Image src={photo ? photo : ""} alt="user" className="w-full aspect-square rounded-2xl object-cover" width={500} height={500} data-sentry-element="Image" data-sentry-source-file="ProviderSummaryCard.jsx" />
      </div>
      <div className="w-full flex items-center justify-between">
        <span className="font-semibold">{fullName}</span>
        <span>⭐5</span>
      </div>
      <div className={`w-full ${bioTextColor} h-20 overflow-hidden leading-relaxed font-light`} style={{
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }}>
        {headline}
      </div>
      <div className="w-full flex items-center gap-4">
        <button type="button" className={`text-${bioButtonColor} border border-${bioButtonColor} rounded-full h-12 w-full`} onClick={handleFullBio}>
          Full Bio
        </button>
        <button type="button" className="bg-new-10 text-white border border-white rounded-full h-12 w-full" onClick={showModal}>
          Book Now
        </button>
      </div>
      <Modal width={600} className="p-0 m-0" centered footer={null} title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} data-sentry-element="Modal" data-sentry-source-file="ProviderSummaryCard.jsx">
        <div className="w-full">
          <RequestCare data-sentry-element="RequestCare" data-sentry-source-file="ProviderSummaryCard.jsx" />
        </div>
      </Modal>
    </div>;
};
export default ProviderSummaryCard;