import Image from "next/image";
const AccordionItem = ({
  object,
  curOpen,
  onOpen
}) => {
  const isOpen = curOpen === object.id;
  return <div className={`mb-4 md:mb-8 border-b pb-4 md:pb-8 border-white-900 font-light sm:font-normal ${isOpen ? "bg-[#42278c] p-4 rounded-lg transition-all ease-in-out duration-300" : "transition-all ease-in-out duration-300"}`} data-sentry-component="AccordionItem" data-sentry-source-file="AccordionItem.jsx">
      <div className="flex justify-between items-center mb-2 md:mb-4 cursor-pointer" onClick={() => onOpen(isOpen ? null : object.id)}>
        <p className="font-light sm:font-normal text-lg w-11/12">
          {object.question}
        </p>
        <div className="switch-icon w-1/12 flex items-center justify-end">
          {isOpen ? <Image src={"xmark.svg"} className="h-4 md:h-8 w-4 md:w-8" alt="close" width={20} height={20} /> : <Image src={"plus.svg"} className="h-4 md:h-8 w-4 md:w-8" alt="open" width={20} height={20} />}
        </div>
      </div>
      {isOpen && (object.isHtml ?
    // <div className="font-light" dangerouslySetInnerHTML={{ __html: object.answer }} /> :
    <>
            <p className="font-light">
              {object.isWeTreat ? "We treat the full range of conditions normally treated in a primary care provider office or an urgent care care center including:" : ""}
            </p>
            <ol className="font-light list-decimal pl-6" dangerouslySetInnerHTML={{
        __html: object.answer
      }} />
          </> : <p className="font-light">{object.answer}</p>)}
    </div>;
};
export default AccordionItem;