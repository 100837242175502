"use client";

import { useRouter } from "next/navigation";
import GeneralFAQAccordion from "./GeneralFAQAccordion";
import Image from "next/image";
const MoreSection = () => {
  const router = useRouter();
  return <section className="text-white w-full items-center justify-center px-4 md:px-16 lg:px-32 pt-8 md:pt-14 pb-12 md:pb-20 bg-parent-400 text-white-100 flex flex-col gap-10" data-sentry-component="MoreSection" data-sentry-source-file="MoreSection.jsx">
      <h3 className="text-2xl md:text-4xl font-semibold text-center">
        Frequently asked questions
      </h3>

      <GeneralFAQAccordion data-sentry-element="GeneralFAQAccordion" data-sentry-source-file="MoreSection.jsx" />

      <div className="bg-parent-200 p-4 rounded-xl text-white-100 text-inherit flex flex-col items-center w-full max-w-[1920px]">
        <Image src={"/avatar-group.svg"} alt="Your SVG" width={150} height={150} className="mb-8" data-sentry-element="Image" data-sentry-source-file="MoreSection.jsx" />
        <h4 className="mb-2 text-base md:text-xl font-semibold">
          Still have questions?
        </h4>
        <p className="mb-8 text-sm md:text-lg font-light">
          Can&apos;t find the answer you&apos;re looking for? Please chat to our
          friendly team.
        </p>
        <a className="bg-white rounded-full py-1 px-3 text-parent-200" href="/help-center">
          Get in touch
        </a>
      </div>
    </section>;
};
export default MoreSection;