"use client";

import { Button, DatePicker, Form, Input, InputNumber, Select } from "antd";
import { allSymptoms, listOfZipCodes } from "../resources/resources";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { useBooking } from "../contexts/BookingProvider";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

// eslint-disable-next-line arrow-body-style
const disabledDate = current => {
  // Can not select days before today
  return current && current < dayjs().startOf("day");
};
const RequestCare = () => {
  const [formFields, setFormFields] = useState({
    date_care_is_needed: "",
    symptom: [],
    otherSymptom: "",
    age_of_patient: "",
    zipcode: ""
  });
  const disabled = !formFields.date_care_is_needed || formFields.symptom.length < 1 || !formFields.age_of_patient || !formFields.zipcode;
  const [requestCareForm] = Form.useForm();
  const {
    flow,
    setBookingData
  } = useBooking();
  const router = useRouter();
  const handleChange = (key, value) => {
    setFormFields(prevInfo => ({
      ...prevInfo,
      [key]: value
    }));
  };
  const handleRequestCare = () => {
    const allSymptoms = formFields.symptom.slice(); // Create a copy of the symptoms array
    if (formFields.otherSymptom.trim() !== "") {
      allSymptoms.push(formFields.otherSymptom.trim());
    }
    const bookingDataWithAllSymptoms = {
      date_care_is_needed: formFields.date_care_is_needed,
      symptom: allSymptoms,
      age_of_patient: formFields.age_of_patient,
      zipcode: formFields.zipcode
    };
    console.log("request care1", bookingDataWithAllSymptoms);
    console.log("request care2", formFields);
    setBookingData(prevInfo => ({
      ...prevInfo,
      ...bookingDataWithAllSymptoms
    }));
    if (flow === "homepage") {
      router.push("/openrequest");
    }
    if (flow === "directbooking") {
      router.push("/openrequest");
    }
    if (flow === "dashboard") {
      router.push("/patient/home/dashboard/bookprovider");
    }
  };
  return <div className="bg-white rounded-3xl p-2" data-sentry-component="RequestCare" data-sentry-source-file="RequestCare.jsx">
      <Form form={requestCareForm} size="large" layout="vertical" name="request_provider_visit" data-sentry-element="Form" data-sentry-source-file="RequestCare.jsx">
        <Form.Item label="What do you need help with?" data-sentry-element="unknown" data-sentry-source-file="RequestCare.jsx">
          <Select mode="multiple" maxTagCount="responsive" allowClear showSearch placeholder="Select symptoms" onChange={value => handleChange("symptom", value)} options={allSymptoms} data-sentry-element="Select" data-sentry-source-file="RequestCare.jsx" />
        </Form.Item>
        <Form.Item label="Other Symptoms?" data-sentry-element="unknown" data-sentry-source-file="RequestCare.jsx">
          <Input placeholder="For symptoms not listed above" onChange={e => handleChange("otherSymptom", e.target.value)} value={formFields.otherSymptom} data-sentry-element="Input" data-sentry-source-file="RequestCare.jsx" />
        </Form.Item>
        <Form.Item label="Date Care Is Needed" data-sentry-element="unknown" data-sentry-source-file="RequestCare.jsx">
          <DatePicker className="w-full" disabledDate={disabledDate} format={{
          format: "YYYY-MM-DD",
          type: "mask"
        }} onChange={(date, dateString) => handleChange("date_care_is_needed", dateString)} data-sentry-element="DatePicker" data-sentry-source-file="RequestCare.jsx" />
        </Form.Item>
        <div className="w-full flex flex-col md:flex-row items-center justify-between md:gap-5">
          <Form.Item rules={{
          min: 0,
          max: 150
        }} label="Age of patient" className="w-full" data-sentry-element="unknown" data-sentry-source-file="RequestCare.jsx">
            <InputNumber min={0} max={150} type="number" className="w-full" placeholder="Enter age of patient" formatter={value => `${value}`} parser={value => value?.replace("-", "")} onChange={value => {
            if (value >= 0) {
              handleChange("age_of_patient", value);
            }
          }} data-sentry-element="InputNumber" data-sentry-source-file="RequestCare.jsx" />
          </Form.Item>
          <Form.Item className="w-full" label="Your zip code" data-sentry-element="unknown" data-sentry-source-file="RequestCare.jsx">
            <Select allowClear showSearch placeholder="Select zip code" onChange={value => handleChange("zipcode", value.split(" ")[0])} options={listOfZipCodes} data-sentry-element="Select" data-sentry-source-file="RequestCare.jsx" />
          </Form.Item>
        </div>
        <Form.Item className="w-full flex items-center justify-center" data-sentry-element="unknown" data-sentry-source-file="RequestCare.jsx">
          <Button type="primary" className="rounded-full px-4 w-full md:w-fit" size="large" disabled={disabled} onClick={handleRequestCare} data-sentry-element="Button" data-sentry-source-file="RequestCare.jsx">
            Request care
          </Button>
        </Form.Item>
      </Form>
    </div>;
};
export default RequestCare;