"use client";

import { useState, useEffect, useRef } from "react";
import { useRouter } from "next/navigation";
import { Modal } from "antd";
import { AUTH_TOKEN_KEY, baseUrl } from "../resources/resources";
import axios from "axios";
import RequestCare from "./RequestCare";
import ProviderSummaryCard from "./ProviderSummaryCard";
import { useBooking } from "../contexts/BookingProvider";
import { setAuthorizationToken } from "../services/api";
import Image from "next/image";
import { motion } from "framer-motion";
import { animationOne, animationTwo, animationThree, fadeIn } from "../resources/animation";
import useScrollDirection from "../hooks/useScroll";
const HeroSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [_loading, setLoading] = useState(true); // loading is unused. Put to use later
  const [providers, setProviders] = useState([]);
  const [subscribetext, setSubscribetext] = useState("submit");
  const [subscribeEmail, setSubscribeEmail] = useState("");
  const router = useRouter();
  const {
    setFlow,
    setIsNewUser
  } = useBooking();
  const scrollDirection = useScrollDirection(); // Get scroll direction

  const animationVariant = scrollDirection === "down" ? fadeIn("down", "spring", 0, 0.5) : fadeIn("up", "spring", 0, 0.5);
  const fetchProviderDetails = async () => {
    try {
      const response = await axios.get(baseUrl + "/practioners/get_all_health_providers/");
      setProviders(response?.data?.data);
    } catch (error) {
      console.error("Error fetching health providers:", error);
    } finally {
      setLoading(false);
    }
  };
  const subscribeNewsletter = async () => {
    setSubscribetext("please_wait...");
    try {
      const response = await axios.post(baseUrl + "/authentication/save_interested_user_email/", {
        email: subscribeEmail
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (response.data.status === "success") {
        setSubscribetext("done!");
      }
      if (response.data.status === "failure") {
        setSubscribetext("done!");
      }
    } catch (err) {
      console.error("Error subscribing to newsletter:", err);
    }
  };
  useEffect(() => {
    fetchProviderDetails();
    localStorage.removeItem("intent");
    localStorage.removeItem(AUTH_TOKEN_KEY);
    setAuthorizationToken(null);
    setFlow(null);
    setIsNewUser("null");
  }, []);
  const showModal = () => {
    // this function now routes to the request care page instead of setIsModalOpen(true).
    router.push("/request");
    setFlow("homepage");
    // setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const scrollContainerRef = useRef(null);
  const handlePrevious = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -scrollContainerRef.current.offsetWidth * 1.05,
        // Move by the container's full width for one card scroll
        behavior: "smooth"
      });
    }
  };
  const handleNext = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: scrollContainerRef.current.offsetWidth * 1.05,
        // Move by the container's full width for one card scroll
        behavior: "smooth"
      });
    }
  };
  return <section className="px-6 md:px-8 lg:px-20 bg-parent-400 py-8 md:py-14 bg-hero-pattern w-full text-white flex flex-col items-center justify-center" data-sentry-component="HeroSection" data-sentry-source-file="HeroSection.jsx">
      <div className="flex flex-col gap-4 items-center justify-start w-full max-w-[1920px]">
        <div className="w-full lg:w-2/3 text-center text-inherit flex flex-col items-center justify-center">
          <motion.h1 variants={animationOne} initial="hidden" animate="visible" className="text-3xl md:text-6xl font-semibold mb-6" data-sentry-element="unknown" data-sentry-source-file="HeroSection.jsx">
            Next Gen Urgent Care Delivered in Your Home
          </motion.h1>
          <motion.p variants={animationTwo} initial="hidden" animate="visible" className="text-base md:text-xl font-normal" data-sentry-element="unknown" data-sentry-source-file="HeroSection.jsx">
            Request a doctor&apos;s visit to your preferred location whenever
            you want.
          </motion.p>
          <motion.p variants={animationOne} initial="hidden" animate="visible" className="text-base md:text-xl font-normal" data-sentry-element="unknown" data-sentry-source-file="HeroSection.jsx">
            Ditch the Hassle. Embrace Convenient, Personalized Primary Care.
          </motion.p>
          <motion.div variants={animationThree} initial="hidden" animate="visible" className="w-full items-center justify-center flex flex-col md:flex-row gap-2 md:gap-5" data-sentry-element="unknown" data-sentry-source-file="HeroSection.jsx">
            <button onClick={showModal} type="button" className="bg-new-10 text-white-100 border border-white-100 rounded-full h-14 w-full md:w-96 mt-6 mb-6">
              Request a provider visit
            </button>
            <span className="hidden md:flex items-center justify-between bg-new-10 text-white-100 border border-white-100 rounded-full h-14 w-full md:w-96 p-1">
              <input className="px-2 bg-new-10 outline-none border-none w-full" type="email" name="email_for_newsletter" id="newsletter_email" placeholder="Enter email for updates" onChange={e => setSubscribeEmail(e.target.value)} />
              <button className="bg-parent-500 text-white-100 border border-white-100 rounded-full h-12 w-fit px-6 hover:bg-parent-700 active:scale-95 transition-all ease-in-out duration-200" type="button" key={2} id="subscribe" onClick={subscribeNewsletter}>
                {subscribetext}
              </button>
            </span>
          </motion.div>
        </div>
        <Modal width={600} className="p-0 m-0" centered footer={null} title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} data-sentry-element="Modal" data-sentry-source-file="HeroSection.jsx">
          <div className="w-full">
            <RequestCare data-sentry-element="RequestCare" data-sentry-source-file="HeroSection.jsx" />
          </div>
        </Modal>
      </div>
      <div className="mt-8 w-full max-w-[1920px] overflow-x-auto transition-all ease-in-out duration-200 noscroll snap-x snap-mandatory snap-center">
        <div className={`w-full flex items-center ${providers?.length < 5 ? "justify-start lg:justify-center" : "justify-start"} overflow-x-auto gap-5 pb-3 noscroll transition-all ease-in-out duration-200 snap-x snap-mandatory snap-center`} ref={scrollContainerRef}>
          {providers?.map((item, index) => {
          return <ProviderSummaryCard key={item.id} rate={index} fullName={item.first_name + " " + item.last_name} headline={item.headline} photo={item.photo} id={item.id} email={item.username} bioButtonColor="white-100" bioTextColor="text-[#ddd]" />;
        })}
        </div>
        <div className="w-full flex items-center justify-end">
          {/* <span>view all doctors</span> */}
          <span className="flex items-center gap-5 md:gap-10 mt-8">
            <button onClick={handlePrevious} type="button">
              <Image src={"/prevNext.svg"} className="w-14 cursor-pointer aspect-square" alt="arrow" width={60} height={60} data-sentry-element="Image" data-sentry-source-file="HeroSection.jsx" />
            </button>
            <button onClick={handleNext} type="button">
              <Image src={"/prevNext.svg"} className="rotate-180 w-14 cursor-pointer aspect-square" alt="arrow" width={60} height={60} data-sentry-element="Image" data-sentry-source-file="HeroSection.jsx" />
            </button>
          </span>
        </div>
      </div>
      <motion.div variants={animationVariant} whileInView="show" initial="hidden" viewport={{
      once: false,
      amount: 0.5
    }} className="mt-6 md:mt-10 w-full max-w-[1920px]" data-sentry-element="unknown" data-sentry-source-file="HeroSection.jsx">
        <p className="text-sm md:text-base">For Patients</p>
        <h2 className="mt-3 font-medium text-2xl md:text-4xl">
          Dinma brings the doctor to you in 3 clicks. We believe quality primary
          and urgent care can be delivered in your home.
        </h2>
        <p className="mt-5 text-base md:text-xl font-extralight">
          Avoid messy appointment booking, long wait time, commute and exposure
          to infections
        </p>
      </motion.div>
      <div className="mt-10 md:mt-16 w-full max-w-[1920px] flex flex-col gap-8 md:flex-row justify-between">
        <motion.div variants={animationVariant} whileInView="show" initial="hidden" viewport={{
        once: false,
        amount: 0.5
      }} className="flex-1" data-sentry-element="unknown" data-sentry-source-file="HeroSection.jsx">
          <Image src={"/chat.svg"} alt="Your SVG" width={60} height={60} data-sentry-element="Image" data-sentry-source-file="HeroSection.jsx" />
          <p className="my-2 text-base md:text-xl font-semibold">Convenience</p>
          <p className="text-sm md:text-base font-light">
            Healthcare built around your needs with care delivered to your home
          </p>
        </motion.div>

        <motion.div variants={animationVariant} whileInView="show" initial="hidden" viewport={{
        once: false,
        amount: 0.5
      }} className="flex-1" data-sentry-element="unknown" data-sentry-source-file="HeroSection.jsx">
          <Image src={"/transparency.svg"} alt="Your SVG" width={60} height={60} data-sentry-element="Image" data-sentry-source-file="HeroSection.jsx" />
          <p className="my-2 text-base md:text-xl font-semibold">
            Transparency
          </p>
          <p className="text-sm md:text-base font-light">
            Clear upfront pricing for medical consultation
          </p>
        </motion.div>

        <motion.div variants={animationVariant} whileInView="show" initial="hidden" viewport={{
        once: false,
        amount: 0.5
      }} className="flex-1" data-sentry-element="unknown" data-sentry-source-file="HeroSection.jsx">
          <Image src={"/quality.svg"} alt="Your SVG" width={60} height={60} data-sentry-element="Image" data-sentry-source-file="HeroSection.jsx" />
          <p className="my-2 text-base md:text-xl font-semibold">Quality</p>
          <p className="text-sm md:text-base font-light">
            Best in class care so you can stay healthy
          </p>
        </motion.div>
      </div>
    </section>;
};
export default HeroSection;