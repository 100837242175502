"use client";

import Image from "next/image";
import { useRouter } from "next/navigation";
const BlogSummaryCard = () => {
  const router = useRouter();
  return <div className="flex flex-col gap-4 items-center justify-start w-full md:min-w-96 md:max-w-96" data-sentry-component="BlogSummaryCard" data-sentry-source-file="BlogSummaryCard.jsx">
      <Image src={"/blogimage4.png"} alt="" className="w-full aspect-video rounded-2xl object-cover origin-top object-top" width={500} height={500} data-sentry-element="Image" data-sentry-source-file="BlogSummaryCard.jsx" />
      <div className="w-full flex items-center gap-5">
        <span className="text-[#53389e]">Our Services</span>
        <span className="text-[#808080]">08 August 2024</span>
      </div>
      <div className="w-full">
        <h1 className="text-xl md:text-2xl font-semibold">
          Dinma: Your Solution for Convenient, On-Demand Medical Care
        </h1>
        <p className="text-[#808080] h-32 overflow-hidden truncate text-wrap">
          Around Fall last year, my 3-year-old was tugging at his ears and we
          saw a telehealth provider. The doctor suspected an ear infection but
          needed to look into his ears to confirm the diagnosis. Since he
          couldn&apos;t do so virtually, he referred us for an in-person
          consultation. It was impossible to get an immediate appointment so I
          had to take time off work to take him to an urgent care center...
        </p>
      </div>
      <div className="w-full ">
        <button type="button" className="h-12 text-[#53389e] hover:bg-parent-60 rounded-full flex items-center justify-center gap-1 hover:gap-4 transition-all ease-linear duration-200 hover:px-4" onClick={() => router.push("/blog")}>
          Read More{" "}
          <Image src={"/ArrowRight.svg"} alt="" className="w-6 h-6" width={500} height={500} data-sentry-element="Image" data-sentry-source-file="BlogSummaryCard.jsx" />
        </button>
      </div>
    </div>;
};
export default BlogSummaryCard;