"use client";

import { useState } from "react";
import BlogSummaryCard from "./BlogSummaryCard";
import axios from "axios";
import { baseUrl } from "../resources/resources";
import { motion } from "framer-motion";
import { animationOne, animationTwo, animationThree, fadeIn } from "../resources/animation";
import useScrollDirection from "../hooks/useScroll";
const BlogSection = () => {
  const [subscribetext, setSubscribetext] = useState("submit");
  const [subscribeEmail, setSubscribeEmail] = useState("");
  const scrollDirection = useScrollDirection(); // Get scroll direction

  const animationVariant = scrollDirection === "down" ? fadeIn("down", "spring", 0, 0.5) : fadeIn("up", "spring", 0, 0.5);
  const subscribeNewsletter = async () => {
    setSubscribetext("please_wait...");
    try {
      const response = await axios.post(baseUrl + "/api/v1/authentication/save_interested_user_email/", {
        email: subscribeEmail
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (response.data.status === "success") {
        setSubscribetext("done!");
      }
      if (response.data.status === "failure") {
        setSubscribetext("done!");
      }
    } catch (err) {
      console.error("Error subscribing to newsletter:", err);
    }
  };
  return <section className="px-6 md:px-8 lg:px-20 bg-[#efefff] py-8 bg-hero-pattern w-full flex flex-col items-center justify-center" data-sentry-component="BlogSection" data-sentry-source-file="BlogSection.jsx">
      <motion.div variants={animationVariant} whileInView="show" initial="hidden" viewport={{
      once: false,
      amount: 0.5
    }} className="w-full max-w-[1920px] flex flex-col gap-2 md:gap-0 md:flex-row md:items-center md:justify-between" data-sentry-element="unknown" data-sentry-source-file="BlogSection.jsx">
        <h2 className=" text-3xl md:text-5xl font-semibold">
          Expert <span className="text-[#53389e]">Health</span> Advice and
          Updates
        </h2>
        <span className="text-[#53389e] text-xs font-semibold">See All</span>
      </motion.div>
      <br />
      <br />
      <div className="w-full max-w-[1920px] overflow-x-auto flex flex-row items-center gap-5 noscroll">
        {[1].map(item => {
        return <BlogSummaryCard key={item} />;
      })}
      </div>
      <br />
      <motion.div variants={animationVariant} whileInView="show" initial="hidden" viewport={{
      once: false,
      amount: 0.5
    }} className="w-full max-w-[1920px] items-center justify-center flex flex-col md:flex-row gap-2 md:gap-5" data-sentry-element="unknown" data-sentry-source-file="BlogSection.jsx">
        <span className="flex md:hidden items-center justify-between bg-new-10 text-white-100 border border-white-100 rounded-full h-14 w-full md:w-96 p-1">
          <input className="px-2 bg-new-10 outline-none border-none w-full" type="email" name="email_for_newsletter" id="newsletter_email" placeholder="Enter email for updates" onChange={e => setSubscribeEmail(e.target.value)} />
          <button className="bg-parent-500 text-white border border-white-100 rounded-full h-12 w-fit px-6 hover:bg-parent-700 active:scale-95 transition-all ease-in-out duration-200" type="button" key={2} id="subscribe" onClick={subscribeNewsletter}>
            {subscribetext}
          </button>
        </span>
      </motion.div>
    </section>;
};
export default BlogSection;