"use client";

import HeroSection from "./components/HeroSection";
import HomeHeader from "./components/HomeHeader";
import BlogSection from "./components/BlogSection";
import MoreSection from "./components/MoreSection";
import Footer from "./components/Footer";
export default function Home() {
  return <main className="flex w-full h-full flex-col items-center justify-start" data-sentry-component="Home" data-sentry-source-file="page.jsx">
      <HomeHeader data-sentry-element="HomeHeader" data-sentry-source-file="page.jsx" />
      <HeroSection data-sentry-element="HeroSection" data-sentry-source-file="page.jsx" />
      <BlogSection data-sentry-element="BlogSection" data-sentry-source-file="page.jsx" />
      <MoreSection data-sentry-element="MoreSection" data-sentry-source-file="page.jsx" />
      <Footer data-sentry-element="Footer" data-sentry-source-file="page.jsx" />
    </main>;
}