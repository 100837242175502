"use client";

import { useState } from "react";
import AccordionItem from "./AccordionItem";
const GeneralFAQAccordion = () => {
  const [curOpen, setCurOpen] = useState(null);
  const arrayOfQueAndAns = [{
    question: "How do I request a provider home visit?",
    answer: `<p className="text-sm sm:text-base">
        Step 1: Enter your symptom, age and location to request care
      </p>
      <p className="text-sm sm:text-base">
        Step 2: Sign up and choose your healthcare provider
      </p>
      <p className="text-sm sm:text-base">
        Step 3: Provider visits you to treat you
      </p>`,
    id: 1,
    isHtml: true,
    isWeTreat: false
  }, {
    question: "What does the service cost?",
    answer: "We charge an all-inclusive fee of $179.99 for each visit. Fee covers the cost of the provider visit, consultation and any medical supplies used during the visit",
    id: 2
  }, {
    question: "What if I need a lab investigation?",
    answer: "We will collect samples during the visit and send the samples to in-network options if you have insurance or find the most affordable option if you are uninsured",
    id: 3
  }, {
    question: "Do you accept insurance?",
    answer: "Not yet, but we will work with you to find in-network options for any downstream needs",
    id: 4
  }, {
    question: "Do you accept FSA, HRA and HSA cards?",
    answer: "Yes, we accept FSA, HRA and HSA cards in addition to credit and debit cards.",
    id: 5
  }, {
    question: "Can I request care for a dependent?",
    answer: "Yes, you can request a doctor visit for yourself or a dependent.",
    id: 6
  }];
  return <div className="w-full max-w-[1920px]" data-sentry-component="GeneralFAQAccordion" data-sentry-source-file="GeneralFAQAccordion.jsx">
      {arrayOfQueAndAns.map(e => {
      return <AccordionItem object={e} key={e.id} curOpen={curOpen} onOpen={setCurOpen} />;
    })}
    </div>;
};
export default GeneralFAQAccordion;